<template>
	<div class="main">
		<div class="nav-lf">
		  <MusicType
		    :width="952"
		    :type="type"
		    :tabsList="tabsList"
		    :dropdownList="dropdownList"
		  ></MusicType>
		</div>
		<div>
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import MusicType from "@/components/content/MusicType.vue";
	import { getMyInfo } from "@/utils/common/user";
	import {
		myInfo
	} from "@/api/Mine";
	export default {
		props: {},
		computed: {},
		components: {
			MusicType
		},
		data(){
			return {
				type: false,
				tabsList: [],
				tabsList1: [{
					name: this.$t("createteamtabs"),
					url: '/personalCenter/teamManagement/createTeam'
				},
				// {
				// 	name: this.$t("reviewworks"),
				// 	url: '/personalCenter/teamManagement/reviewWorks'
				// },
				// {
				// 	name: this.$t("managingmembers"),
				// 	url: '/personalCenter/teamManagement/managingMembers'
				// },
				// {
				// 	name: this.$t("inviteteammembers"),
				// 	url: '/personalCenter/teamManagement/inviteMembers'
				// },
				],
				tabsList2: [
				// {
				// 	name: this.$t("createteamtabs"),
				// 	url: '/personalCenter/teamManagement/createTeam'
				// },
				{
					name: this.$t("reviewworks"),
					url: '/personalCenter/teamManagement/reviewWorks'
				},
				{
					name: this.$t("managingmembers"),
					url: '/personalCenter/teamManagement/managingMembers'
				},
				{
					name: this.$t("inviteteammembers"),
					url: '/personalCenter/teamManagement/inviteMembers'
				},
				],
				tabsList3: [
				// {
				// 	name: this.$t("createteamtabs"),
				// 	url: '/personalCenter/teamManagement/createTeam'
				// },
				// {
				// 	name: this.$t("reviewworks"),
				// 	url: '/personalCenter/teamManagement/reviewWorks'
				// },
				{
					name: this.$t("teammembers"),
					url: '/personalCenter/teamManagement/managingMembers'
				},
				// {
				// 	name: this.$t("inviteteammembers"),
				// 	url: '/personalCenter/teamManagement/inviteMembers'
				// },
				],
				dropdownList: [],
				infoObj: {}
			}
		},
		watch: {
			"$store.state.groupType": {
				handler(newValue, oldValue) {
					console.log("groupType变化:", newValue);
					if(newValue == 0){
						this.tabsList = this.tabsList1
					}else{
						if(this.$store.state.isBoss == 1){
							this.tabsList = this.tabsList2
						}else if(this.$store.state.isBoss == 0){
							this.tabsList = this.tabsList3
						}
						
					}
				},
			},
			"$store.state.isBoss": {
				handler(newValue, oldValue) {
					console.log("groupType变化:", newValue);
					if(newValue == 0){
						this.tabsList = this.tabsList3
					}else{
						this.tabsList = this.tabsList2
						
					}
				},
			},
		},
		methods: {
			getMyInfo,
			MyInfo(){
				myInfo({}).then((res) => {
				      console.log(res,'我的信息成功');
					  this.infoObj = res.data
					  this.$store.state.bankList = res.data.bankcard
					  this.$store.state.groupType = res.data.group_type
					  this.$store.state.isBoss = res.data.is_boss
					  this.$store.state.groupId = res.data.group_id
					  localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
					  // console.log(this.$store.state.groupType,'userInfouserInfouserInfo222222222222')
					  console.log(this.infoObj,'JSON.stringify(this.infoObj)');
					  if(this.$store.state.groupType == 0){
					  	this.tabsList = this.tabsList1
					  }else{
					  	if(this.$store.state.isBoss == 1){
					  		this.tabsList = this.tabsList2
					  	}else if(this.$store.state.isBoss == 0){
					  		this.tabsList = this.tabsList3
					  	}
					  	
					  }
					  // if(this.$store.state.groupType == 0){
					  // 	this.tabsList = this.tabsList1
					  // }else{
					  // 	this.tabsList = this.tabsList2
					  // }
				    })
				    .catch((err) => {
				      console.log(err,'我的信息失败');
				    });
			}
		},
		created() {
			// 我的信息
			this.MyInfo();
			// this.tabsList = this.tabsList1
			console.log(this.$store.state.groupType,'userInfouserInfouserInfo222222222222')
			
		}
	}
</script>

<style lang="less" scoped>
	.main{
		// color: palevioletred;
		margin-left: 28px;
		::v-deep .nav .el-menu-item.is-active {
			border-bottom: 2px solid @lineColor;
		}
		// ::v-deep .nav .el-menu-item.is-active::after{
		// 	position: absolute;
		// 	content: '';
		// 	background-color: #ffffff;
		// 	right: 0;
		// 	top: 50%;
		// 	transform: translate(0, -50%);
		// 	height: 30rpx;
		// 	width: 15rpx;
		// 	border-radius: 15px 0 0 15px;
		// }
	}
</style>